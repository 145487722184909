@import './Generics/';
html{
    font-size: 15px;
    background-color: $white;
}
html,
#root{
    overflow:hidden;
}
*{
    box-sizing:  border-box;
}