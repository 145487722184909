@import '../../Generics/';
.buttonLeft{
    width: auto;
    max-width: 33%;
    align-self: flex-end;
    @media(max-width: $breakpoint-xs){
        max-width: 100%;
    }
}

.ServiceListContainer{
    & small {
        font-size:0.85rem;
    }
    & >  * {
        width: 100%;
    }
    & ul{
        list-style-type: trad-chinese-formal;
        font-size: 1.2rem;
        & div{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            & span {
                font-weight: bold;
            }
        }
        & li {
            margin-bottom: 1.2rem;
        }
        & * li{
            margin-bottom: 0.3rem;
            color: $grey;
        }
        & ul {
            list-style: disclosure-closed;
            font-size:1rem;
        }
    }
}