@import '../../Generics/colors';
.Host{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .one{
        font-family: Kurier, sans-serif;
        color: $red;
        font-size:3rem;
    }
    & .two{
        font-family: Trirong, serif;
        color: $green;
        font-size: 1.5rem;
    }
}