@import '../../Generics/colors';
@import '../../Generics/sizes';
.hideOnDesktop{
    @media(min-width: $breakpoint-md){
        display:none;
    }
}
.Host{
    position: absolute;
    width: 100vw;
    height: 8vh;
    padding: 0.33rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .2s ease-in;
    border-bottom: 0.33rem solid $green;
    box-shadow: $black 0px 0px 5px 1px;
    z-index:999;
    @media(max-width: $breakpoint-md) {
        height: 9vh;
    }
    & .logoContainer{
        height: 100%;
        width: auto;
        max-width: 50%;
        & .logo{
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: 100%;
            object-fit: contain;
        }
    }
    & .mobileContainer{
        height: 4vh;
        width: 4vh;
        padding:1vh;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2ms linear;
        @media(max-width: $breakpoint-md){
            height:6vh;
            width:6vh;
        }
        & svg{
            height: 100%;
            width:100%;
            fill: $green;
            transition: all 0.2ms linear;
        }
        &:hover,
        &:active{
            background-color: $green;
            & svg{
                fill: $white;
            }
        }
    }
    & .link{
        font-family: Kurier, sans-serif;
        color: $black;
        text-decoration: none;
        font-size: 1.5rem;
        &:hover,
        &.active{
            color: $red;
            text-decoration: underline $red;
        }
    }
    & .mobileMenu{
        position: fixed;
        top: 0;
        left:0;
        height: 100vh;
        width: 100vw;
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(red($green), green($green), blue($green), 0.98);
        cursor: pointer;
        & .link{
            margin: 1rem;
            width: calc(100% - 2rem);
            text-align: center;
            border-bottom: $green-dark 0.3rem solid;
            flex: 3 1 auto;
        }
        & .closeContainer{
            width: 7vh;
            height: 7vh;
            padding:1vh;
            border-radius: 50%;
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            & svg{
                width: 100%;
                height: 100%;
                & line{
                    stroke-width: 1rem;
                    stroke: $white;
                    stroke-linecap: round;
                }
            }
            &:hover,
            &:active{
                background-color: $white;
                & svg{
                    & line{
                        stroke: $green;
                    }
                }
            }
            
        }
    }
    & .linkContainer{
        display:flex;
        justify-content: space-evenly;
        & .link{
            margin: 0 1rem;
        }
    }
    &.scrolled{
        position: fixed;
        top: 0;
        left: 0;
        background-color: $green;
        border: none;
        box-shadow: none;
        & .logo{
            filter: brightness(0) invert(1);
        }
        & .link{
            color: $white;
        }
        & svg{
            fill: $white;
        }
    }
}