/*Trirong*/
@font-face{
    font-family: 'Trirong';
    src: url(../Assets/Fonts/Trirong/Trirong-Regular.ttf);
    font-weight: normal;
    letter-spacing: 0.05rem;
}
@font-face{
    font-family: 'Trirong';
    src: url(../Assets/Fonts/Trirong/Trirong-Bold.ttf);
    font-weight: bold;
    letter-spacing: 0.05rem;
}
@font-face{
    font-family: 'Trirong';
    src: url(../Assets/Fonts/Trirong/Trirong-ExtraBold.ttf);
    font-weight: bolder;
    letter-spacing: 0.05rem;
}
@font-face{
    font-family: 'Trirong';
    src: url(../Assets/Fonts/Trirong/Trirong-Light.ttf);
    font-weight: lighter;
    letter-spacing: 0.05rem;
}
/*Kurier*/
@font-face{
    font-family: 'Kurier';
    src: url(../Assets/Fonts/Kurier/Kurier-Regular.ttf);
    font-weight: normal;
}