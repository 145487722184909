@import '../../Generics/';

.address{
    & > *{
        display: block;
        margin-bottom:2rem;
        font-size:2rem;
        &:nth-last-child(1){
            margin-bottom: 0;
        }
        &::before{
            content: '';
            display: inline-block;
            margin-left: -2rem;
            width: 2rem;
            height: 100%;
            font-size:75%;
        }
        &.name{
            font-size:3rem;
            &::before{
                content: '';
            }
        }
        &.location{
            &::before{
                content: '';
            }
        }
        &[href^="mailto:"]{
            &::before{
                content: '\2709';
            }
        }
        &[href^="tel:"]{
            &::before{
                content: '\2706';
            }
        }
    }
    & a{
        color: $green;
        text-decoration: none;
        &[href|="mailto"]{
            &::before{
                content: 'm'
            }
        }
    }
}
.map{
    & > iframe{
        width: 100%;
        height: 100%;
        border:0;
    }
}