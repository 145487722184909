@import '../../Generics/';

.portretContainer{
    & *{
        &:not(li):not(.horizontalRow){
            display: inline !important;
            @media(max-width: $breakpoint-md){
                display: block !important;
            }
        }
        &::after{
            content:'';
            display:block;
            margin: 1rem 0;
        }
    }
    & ul {
        padding:unset;
        list-style: trad-chinese-formal inside;
    }
}
.portret{
    float: left;
    shape-outside: url(../../Assets//Images/kris_circleCropped.png);
    shape-margin: 2rem;
    margin-right: 2rem;
    @media(max-width: $breakpoint-xs){
        float: none;
    }
}