@import '../../Generics/';

.homeImage{
    margin-top:-2rem;
    margin-left:-5rem;
    margin-bottom: 2rem;
    width: 100vw;
    border-bottom: 0.33rem solid $green;
    box-shadow: $black 0px 0px 5px 1px;
    @media (max-width: $breakpoint-md){
        margin-top: -1rem;
        margin-left: -2rem;
    }
}
