@import '../../Generics';
.HeaderImageContainer{
    position: relative;
    top: -2rem;
    margin-left:-5rem;
    width: 100vw;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    @media (max-width: $breakpoint-md){
        top: -1rem;
        margin-left:-2rem;
    }
    &::after{
        content: '';
        background-color: $green;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        z-index: 2;
    }
    & img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
        z-index: 1;
        object-fit: cover;
        object-position: center;
    }
    & span{
        color: $white;
        font-size: 3rem;
        font-family: Kurier, sans-serif;
        z-index: 5;
        text-align: center;
        @media(max-width: $breakpoint-xs){
            font-size:2rem;
        }
    }
}