
h1,
h2,
h3,
h4,
h5,
h6, 
strong,
title,
b{
    font-family: Kurier, sans-serif;
    color: $red;
}
.hideOnMobile{
    @media(max-width: $breakpoint-md){
        display: none;
    }
}
.Host{
    position:absolute;
    top: 8vh;
    padding: 2rem 5rem 1rem 5rem;
    scroll-behavior: smooth;
    height: calc(100% - 8vh);
    width: 100%;
    overflow: auto;
    @media (max-width: $breakpoint-md){
        padding: 1rem 2rem;
    }
    @media(max-width: $breakpoint-md) {
        top: 9vh;
        height: calc(100% - 9vh);
    }
    & .responsive-image{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    & .horizontalRow{
            display: flex;
            flex-direction: row;
            & > * {
                flex-grow: 2;
                flex-shrink: 0.5;
                margin: 0 0.5rem;
                &:nth-child(1){
                    margin-left: 0;
                }
                :nth-last-child(1){
                    margin-right: 0;
                }
            }
            & > article {
                flex-basis: 60%;
            }
            & > aside {
                flex-basis: 40%;
            }
            @media (max-width: $breakpoint-md) {
                flex-direction: column;
                & > *{
                    margin: 0.5rem 0;
                    flex-basis: 100%;
                }
            }
        }
    & section{
        font-size: 1.2rem;
        margin-bottom: 2rem;
        font-family: Trirong, serif;
        letter-spacing: 0.05rem;
        &:nth-last-child(1){
            margin-bottom: none;
        }
        & button {
            display: block;
            appearance: none;
            background-color: $green;
            border: none;
            padding: 0.5rem 0.7rem;
            font-family: Kurier, sans-serif;
            color: $white;
            margin: 1rem 0;
            &:hover,
            &:active{
                background-color: $green-dark;
                box-shadow: #A8A8A8 0.1rem 0.2rem 0.5rem 0.1rem;
                cursor: pointer;
            }
            &.green{
                    background-color: $green;
                    &:hover,
                    &:active{
                        background-color: $green-dark;
                    }
                }
        }
        & title{
            font-family: Kurier, sans-serif;
            color: $green;
            font-size:2rem;
            letter-spacing: normal;
        }
        & span,
        & title {
            display: inline-block;
            margin-bottom: 0.6rem;
            &:nth-last-child(1){
                margin-bottom: none;
            }
        }
    }
}