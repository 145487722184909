$black: #04060D;
$grey: #3C3D40;
$grey-light: lighten($grey, 65%);
$grey-dark: darken($grey, 15%);
$green: #80A685;
$green-light: lighten($green, 15%);
$green-dark: darken($green, 15%);
$red: #BF3636;
$red-light: lighten($red, 15%);
$red-dark: darken($red, 15%);
$white: #F2F2F2;